import React,{useEffect,useState} from 'react'
import Sidebar from './Sidebar'
import { Link } from 'react-router-dom'
import Navbar from './Navbar'
import axios from 'axios'
import { baseUrl } from "../axiosurl"
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import { IsLogin } from "../isValidLogin";

export default function Music_object() {
    let navigate = useNavigate()
    const [object,setObject] = useState("")

    const getMusicObject = ()=>{
        axios.get(`/music_object`).then((result)=>{
            setObject(JSON.stringify(result.data))
        })
    }

    useEffect(()=>{
        IsLogin(navigate)
        getMusicObject()
    },[])

    const musicUpdate = ()=>{

        function isJsonObject(strData) {
            try {
                JSON.parse(strData);
            } catch (e) {
                return false;
            }
            return true;
        }

        if(isJsonObject(object)){
            axios.post(`/music/create_update`,JSON.parse(object)).then((result)=>{
                toast.success('Update Music Object Successfully!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                getMusicObject()
            }).catch((error)=>{
                toast.error('Error JSON Object Not Update!!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
            })
        }else{
            toast.error('Error JSON Object Not Update!!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }

    }

    return (
        <>
            <Sidebar />
            <div className="main-content">
                <Navbar />

                <div className="header bg-gradient-primary pb-8 pt-5 pt-md-8">
                    <div className="container-fluid">
                        <div className="header-body">
                            <div className="row">
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid mt--7">
                    <div className="row">
                        <div className="col">
                            <div className="card shadow">
                                <div className="card-header border-0">
                                    <h3 className="mb-0">Music Object</h3>
                                </div>
                                <div className="table-responsive">
                                    <table className="table align-items-center table-flush">
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">Object</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <textarea contentEditable rows="30" cols="170" value={object} onChange={(e)=>setObject(e.target.value)} > 
                                                    
                                                    </textarea>
                                                </td>
                                                <td>
                                                    <button type="button" class="btn btn-warning btn-sm" onClick={() => musicUpdate()}>Update</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
