import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Music from "./pages/Music";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Music_object from "./pages/Music_object";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="dashboard"  element={<Dashboard />} />
        <Route path="game_music" element={<Music />} />
        <Route path="music_object_data" element={<Music_object />} />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
