import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import { Link } from 'react-router-dom'
import Navbar from './Navbar'
import axios from 'axios'
import { baseUrl } from "../axiosurl"
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import { IsLogin } from "../isValidLogin";

export default function Music() {
    let navigate = useNavigate()
    const [selectedFile, setSelectedFile] = useState();

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    const [music, setMusic] = useState([])

    const getMusicObject = () => {
        axios.get(`/music`).then((result) => {
            setMusic(result.data.data)
        })
    }

    useEffect(() => {
        IsLogin(navigate)
        getMusicObject()
    }, [])

    const musicDelete = (musicName) => {
        axios.post(`/music/delete`, { musicName }).then((result) => {
            toast.success(`Delete Music ${musicName} Successfully!`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            getMusicObject()
        })
    }

    const handleSubmission = async (e) => {
        e.preventDefault()
        const formData = new FormData();
        formData.append('myfile', selectedFile);
        axios.post(`/music/upload`, formData).then((result) => {
            getMusicObject()
        })
    }

    return (
        <>
            <Sidebar />
            <div className="main-content">
                <Navbar />

                <div className="header bg-gradient-primary pb-8 pt-5 pt-md-8">
                    <div className="container-fluid">
                        <div className="header-body">
                        </div>
                    </div>
                </div>
                <div className="container-fluid mt--7">
                    <div className="row">
                        <div className="col">
                            <div className="card shadow">
                                <div className="card-header border-0" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <h3 className="mb-0">Music</h3>
                                    <div>
                                        <form onSubmit={handleSubmission} encType='multipart/form-data'>
                                            <input type="file" name="myfile" onChange={changeHandler} required />
                                            <button type="submit" className="btn btn-primary btn-sm" >Add Music</button>
                                        </form>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table align-items-center table-flush">
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">Music</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                music.map((musicName) => {
                                                    return (
                                                        <tr key={musicName}>
                                                            <td scope="row">
                                                                {musicName}
                                                            </td>
                                                            <td>
                                                                <button type="button" className="btn btn-danger btn-sm" onClick={() => musicDelete(musicName)}>Delete</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
